<template>
  <div>
    <div class="s-orders-nav">
      <div class="s-basis-1/4">
        <button class="s-orders-btn ml-3 pl-3 pr-3" @click="back">返回</button>
      </div>
      <div class="text-center s-basis-1/2">訂單明細</div>
    </div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <DetailSection class="m-3" icon="info" title="消費資訊">
        <table class="s-text-sm s-w-full">
          <template v-if="orderFieldSetting.length > 0">
            <tr :key="i" v-for="(field, i) in orderFieldSetting">
              <td class="s-font-semibold s-text-primary-text">{{ field.value }}</td>
              <td class="s-text-right s-text-black">{{ getOrderData(field.key) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
                <td class="s-font-semibold s-text-primary-text">訂單編號</td>
                <td class="s-text-right s-text-black">{{ order.order_number }}</td>
            </tr>
            <tr>
                <td class="s-font-semibold s-text-primary-text">訂單時間</td>
                <td class="s-text-right s-text-black">{{ datetime }}</td>
            </tr>
            <tr>
                <td class="s-font-semibold s-text-primary-text">訂單金額</td>
                <td class="s-text-right s-font-semibold s-text-black">NT$ {{ $currency(order.amount, '') }}</td>
            </tr>
            <tr>
                <td class="s-font-semibold s-text-primary-text">訂單狀態</td>
                <td class="s-text-right s-font-semibold s-text-black">{{ order.order_status }}</td>
            </tr>
            <tr>
                <td class="s-font-semibold s-text-primary-text">運送狀態</td>
                <td class="s-text-right s-font-semibold s-text-black">{{ order.delivery_status }}</td>
            </tr>
          </template>
        </table>
      </DetailSection>
      <DetailSection class="m-3" icon="list" title="消費明細">
        <table class="s-text-sm s-w-full">
          <thead>
            <tr class="s-font-semibold">
              <template v-if="orderItemFieldSetting.length > 0">
                <th v-for="(field, i) in orderItemFieldSetting" :key="i" :class="itemThClass(i)">
                    {{ field.value }}
                </th>
              </template>
              <template v-else>
                <th class="s-text-primary-text">名稱</th>
                <th class="s-text-right s-min-w-10 s-text-black">數量</th>
                <th class="s-text-right s-min-w-10 s-text-black">金額</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(item, i) in items">
              <template v-if="orderItemFieldSetting.length > 0">
                <td v-for="(field, j) in orderItemFieldSetting" :key="j" :class="itemTdClass(j)">
                  {{ item[field.key] }}
                </td>
              </template>
              <template v-else>
                <td class="s-text-primary-text">{{ item.name }}</td>
                <td class="s-text-right s-text-black">{{ item.quantity }}</td>
                <td class="s-text-right s-text-black">{{ item.price }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </DetailSection>
    </template>
  </div>
</template>

<script>
  import moment from 'moment'
  import orderListPage from '@/mixins/liff/orderList/page'
  import DetailSection from '@/components/Page/Liff/OrderList/DetailSection'

  export default {
    mixins: [orderListPage],
    inject: ['$currency'],
    components: { DetailSection },
    data: () => ({
      order: {},
      from: null,
    }),
    computed: {
      items () {
        return this.order.items || []
      },
      datetime () {
        return moment(this.order.order_at).format('YYYY-MM-DD HH:mm')
      },
      orderStatusMapping () {
        return this.sourceConfig.order_status_mapping
      },
      deliveryStatusMapping () {
        return this.sourceConfig.delivery_status_mapping
      },
      orderFieldSetting() {
        const orderSetting = this.sourceConfig.field_setting?.detail?.order ?? []
        return orderSetting.filter((s) => s.is_show ).sort((a, b) => a.order - b.order);
      },
      orderItemFieldSetting() {
        const itemSetting = this.sourceConfig.field_setting?.detail?.item ?? []
        return itemSetting.filter((s) => s.is_show ).sort((a, b) => a.order - b.order);
      }
    },
    methods: {
      async fetch () {
        this.order = await this.$api.getDetail({ id: this.$route.params.id, provider: this.source })
      },
      back () {
        if (this.from && this.from.fullPath !== '/') {
          return this.$router.push(this.from)
        }

        this.$router.push({
          name: 'LiffOrderListIndex',
          query: { source: this.$route.query.source }
        })
      },
      itemThClass(i) {
        if (i == 0) {
          return 's-text-primary-text'
        } else {
          return 's-text-right s-min-w-10 s-text-black'
        }
      },
      itemTdClass(i) {
        if (i == 0) {
          return 's-text-primary-text'
        } else {
          return 's-text-right s-text-black'
        }
      },
      getOrderData(key) {
        if (key === 'order_at') return this.datetime
        if (key === 'amount') return this.$currency(this.order.amount, '')
        return this.order[key] ?? ''
      }
    },
    beforeRouteEnter(_, from, next) {
      next((vm) => {
        vm.from = from
      })
    },
  }
</script>

<style scoped>
  .s-orders-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
    background-color: var(--s-white);
  }

  .s-orders-btn {
    color: var(--s-gray);
    border: 1px solid var(--s-gray);
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    padding: 2px 6px;
  }

  table th,
  table tr:not(:last-child) td {
    padding-bottom: 1.25rem;
  }

  .s-text-primary-text {
    color: var(--liff-primary_text_color);
  }

  .s-text-black {
    color: var(--s-gray-darker);
  }
</style>
