import Client from './Client'

class OrderList extends Client {
  async getList(payload) {
    let params = { page: payload.page };
    if (payload.start_at) {
      params.start_at = payload.start_at
      params.end_at = payload.end_at
    }
    const { data, meta: { last_page: lastPage } } = await this.get(`/${payload.provider}`, params)

    return { data, lastPage }
  }

  async getDetail({ provider, id }) {
    const { data } = await this.get(`/${provider}/${id}`)

    return data
  }
}

export default new OrderList({
  baseURL: '/:orgCode/liff/order-list',
})
