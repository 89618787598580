import main from './main'
import withFetcher from '@/mixins/liff/withFetcher'
import Loading from '@/components/Page/Liff/Shared/Loading'
import api from '@/apis/liff/v2/orderList'

export default {
  mixins: [main, withFetcher],
  components: { Loading },
  computed: {
    $api() {
      return api
    },
    sources() {
      const sources = this.moduleConfig.data_source || []

      return sources.sort((a, b) => a.order - b.order).map((source) => ({
        id: source.provider,
        ...source,
      }))
    },
    source () {
      return this.$route.name.match(/.*Tab$/) ? this.$route.params.source : (this.$route.query.source || this.sources[0].id);
    },
    sourceConfig () {
      return this.sources.find(source => source.id === this.source) || {}
    },
  },
}

