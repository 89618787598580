import https from './https'
import store from '@/store'

function trim(str, charlist) {
  // eslint-disable-next-line no-useless-escape
  charlist = !charlist ? ' \s\xA0' : (charlist + '').replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '$1')

  return str.replace(new RegExp('^[' + charlist + ']+|[' + charlist + ']+$', 'g'), '')
}

export default class Client {
  constructor ({ baseURL }) {
    this.baseURL = baseURL
  }

  withBaseURL(url) {
    return `${trim(this.baseURL, '/')}/${trim(url, '/')}`
  }

  withOrgCode(url) {
    return url.replace(/:orgCode/, store.state.liffGeneral.orgCode)
  }

  async request (method, url, data = {}) {
    url = this.withOrgCode(this.withBaseURL(url))

    console.debug(`request: ${method} ${url} ${JSON.stringify(data)}`)

    const response = await https.request({
      method,
      url,
      data,
    })

    console.debug(`response: ${JSON.stringify(response.data)}`)

    return response.data
  }

  cleanQuery (query) {
    const result = {}

    for (const [key, value] of Object.entries(query)) {
      if (value === undefined || value === null) continue
      result[key] = value
    }

    return result
  }

  buildUrl (url, query) {
    if (Object.keys(query).length === 0) {
      return url
    }

    const params = new URLSearchParams(this.cleanQuery(query)).toString()

    return `${url}?${params}`
  }

  get (url, query = {}) {
    return this.request('get', this.buildUrl(url, query))
  }

  post (url, data = {}) {
    return this.request('post', url, data)
  }

  put (url, data = {}) {
    return this.request('put', url, data)
  }

  'delete' (url, data = {}) {
    return this.request('delete', url, data)
  }
}
